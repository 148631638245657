import s from "../Modules/Modules.module.css";
import m from "./CourseEx.module.css";
import { CourseDescription, PageTitle, SubTitle } from "../Modules/Modules";
import { TeacherBlock } from "./CourseExample1";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setUserData } from "../../Redux/user/userActions";
import axios from "axios";

const TeenagersCourse2 = () => {
  return (
    <div className={m.main}>
      <div className={m.coursePage}>
        <div className={m.courseLeft}>
          <SubTitle title="Подростковые центры" />
          <PageTitle title=" РЕГИСТРАЦИЯ ЗАКРЫТА! Организация работы с подростками группы риска и подростками, состоящими на учете в органах и учреждениях системы профилактики безнадзорности и правонарушений несовершеннолетних" />
          <TeacherBlock
            name="Ирина Муравьева"
            position="Ведущий аналитик отдела образовательных курсов и просветительских материалов"
          />
          {window.innerWidth < 976 ? (
            <div className={m.courseRight}>
              <p></p>
              {/* <BuyWindow/> */}
            </div>
          ) : (
            <></>
          )}
          <p></p>
          {/*<div className={m.courseImg}>*/}
          {/*    <img src={bc} alt=""/>*/}
          {/*</div>*/}
          <CourseDescription title="Описание">
            <CourseDesc />
          </CourseDescription>
          <CourseDescription title="Описание">
            <CourseDesc2 />
          </CourseDescription>
        </div>
        {window.innerWidth >= 976 ? (
          <div className={m.courseRight}>{/* <BuyWindow/> */}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default TeenagersCourse2;

const CourseDesc = () => {
  return (
    <div className={s.courseDescription}>
      <strong>Даты проведения обучения:</strong>
      <br style={{ fontSize: "24px" }} />5 ноября – 29 ноября 2024 г.
      <p></p>
      <strong>Цель реализации программы:</strong>
      <br style={{ fontSize: "24px" }} />
      Совершенствование компетенций специалистов системы профилактики в области
      работы с подростками группы риска и подростками, состоящими на учете в
      органах и учреждениях системы профилактики безнадзорности и правонарушений
      несовершеннолетних, в рамках сети подростковых центров «Подростки России»
      <p></p>
      <strong>Характеристики курса</strong>
      <br style={{ fontSize: "24px" }} />
      Трудоемкость программы: 36 ак. часов <br />
      Длительность (недель): 4<br />
      Учебная нагрузка по курсу: до 3 часов в день
      <br />
      Язык курса: русский
      <br />
      Удостоверение установленного образца: да.
      <p></p>
      <strong>
        Для получения удостоверения о повышении квалификации необходимо:
      </strong>
      <br style={{ fontSize: "24px" }} />
      <ul>
        <li>
          Зарегистрироваться на курс на портале Центра защиты прав и интересов
          детей;
        </li>
        <li>
          Заполнить шаблоны документов и прикрепить их в личном кабинете на
          портале (
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/Личное_заявление_физического_лица_RKYccAx.docx"
          >
            личное заявление физического лица
          </a>
          ,{" "}
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/Заявка_о_приеме_на_обучение_от_учреждения_eO0fc7g.docx"
          >
            заявка о приеме на обучение от учреждения
          </a>
          ,{" "}
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd.docx"
          >
            согласие на обработку персональных данных
          </a>{" "}
          и{" "}
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd_subject.docx"
          >
            согласие на обработку персональных данных, разрешенных субъектом
            персональных данных для распространения
          </a>
          ).
        </li>
        <li>
          Прикрепить в личном кабинете на портале сканы паспорта, СНИЛСа,
          диплома об образовании, документа о смене ФИО, если оно не совпадает в
          дипломе и паспорте;
        </li>
        <li>
          Отправить <strong>оригиналы</strong> заявлений и согласий по адресу:{" "}
          <strong>
            115093, г. Москва, Щипок, д.18, ФГБУ Центр защиты прав и интересов
            детей, Отдел ДПО (подростки)
          </strong>
          ;
        </li>
        <li>
          Посещение онлайн-занятий, изучение материалов на учебной платформе;
        </li>
        <li>Успешная защита итогового проекта.</li>
      </ul>
    </div>
  );
};

const CourseDesc2 = () => {
  return (
    <div className={s.courseDescription}>
      <strong>Необходимые документы и их шаблоны:</strong>
      <br style={{ fontSize: "24px" }} />
      <ul>
        <li>
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/Личное_заявление_физического_лица_RKYccAx.docx"
          >
            Личное заявление физического лица
          </a>
          ;
        </li>
        <li>
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/Заявка_о_приеме_на_обучение_от_учреждения_eO0fc7g.docx"
          >
            заявка о приеме на обучение от учреждения
          </a>
          ;
        </li>
        <li>
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd.docx"
          >
            согласие на обработку персональных данных
          </a>
          ;
        </li>
        <li>
          <a
            target="_blank"
            href="https://dpo.fcprc.ru/media/media/behavior/soglasie_pd_subject.docx"
          >
            согласие на обработку персональных данных, разрешенных субъектом
            персональных данных для распространения
          </a>
          .
        </li>
      </ul>
      Документы нужно скачать, распечатать и заполнить, затем отсканировать и
      перевести в pdf-формат, загрузить в личный кабинет.
      <p></p>
      <strong>Оригиналы</strong> этих документов нужно направить по адресу:
      <p></p>
      <b>
        115093, г. Москва, Щипок, д.18, ФГБУ Центр защиты прав и интересов
        детей, Отдел ДПО (подростки)
      </b>
    </div>
  );
};

const BuyWindow = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchData = async () => {
    let data1 = {
      groups: user.groups.concat([11]),
    };
    let config1 = {
      method: "patch",
      maxBodyLength: Infinity,

      url: "https://dpo.fcprc.ru/api/usersdata",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
      data: JSON.stringify(data1),
    };
    axios.request(config1);

    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: "https://dpo.fcprc.ru/api/usersdata",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    };
    dispatch(setUserData(config));
  };

  return (
    <div className={m.buy}>
      <div className={m.info}>
        <div style={{ fontSize: "18px", paddingBottom: "5px" }}>
          <strong>Принять участие</strong>
        </div>
        <div
          style={{ fontSize: "12px", paddingBottom: "30px", color: "#808080" }}
        >
          36 академических часов
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <div style={{ fontSize: "12px", color: "#808080" }}>
            Стоимость курса
          </div>
          <div style={{ fontSize: "18px" }}>
            <strong>Бюджетный</strong>
          </div>
        </div>
        <div className={m.buyButton}>
          {isAuthenticated ? (
            !user.groups.includes(11) ? (
              <NavLink onClick={() => fetchData()}>Записаться</NavLink>
            ) : (
              <NavLink to="#">Вы записаны!</NavLink>
            )
          ) : (
            <NavLink to="/acc_registration">Регистрация</NavLink>
          )}
        </div>
      </div>
    </div>
  );
};
