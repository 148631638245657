import {PageTitle} from "../Modules/Modules";
import s from "./Main.module.css"

const Contacts = () => {
    return (
        <div className={s.main}>
            <PageTitle title="Контакты"/>
            <p></p>
            <div className={s.contacts}>
                <a href="https://yandex.ru/maps/-/CDD2Z28z">Фактический
                    адрес: 119435, г. Москва, ул. Погодинская, д.8/1</a>
                <p></p>
                <a href="https://yandex.ru/maps/213/moscow/house/ulitsa_shchipok_18/Z04YcARjSkYPQFtvfXtzcnRkZQ==/?indoorLevel=1&ll=37.633338%2C55.723871&z=17.08">Почтовый
                    адрес: 115093, г. Москва, Щипок, д.18</a>
                <p></p>
                <a href="https://yandex.ru/maps/213/moscow/?z=17&mode=search&text=115093%2C+г.+Москва%2C+ул.+Люсиновская%2C+д.+51">
                    Юридический адрес: 115093, г. Москва, ул. Люсиновская, д. 51
                </a>
                <p></p>
                <a href="tel:+74994440806,100%2008">+7 (499) 444-08-06, доб. 100 77</a>
                <p></p>
                <a href="mailto:dpo@fcprcgov.ru">Поддержка: dpo@fcprcgov.ru</a>
                <p></p>
                <a href="https://t.me/dpoCZPID">Telegram: @dpoCZPID</a>
            </div>
        </div>
    )
}

export default Contacts